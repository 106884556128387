@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
:root {
    --main-font: 'Source Code Pro', Menlo, Monaco, Consolas, "Courier New", monospace;
    --background-color: #fafafa;
    --link-color: black;
    --link-hover-font-weight: 600;
}

body {
    margin: 0;
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, "Courier New", monospace;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
    background-color: var(--background-color);
}

a {
    color: black;
    color: var(--link-color);
    text-decoration: none;
}

a:hover {
    font-weight: 600;
    font-weight: var(--link-hover-font-weight);
}

.mePic {
    width: 450px;
}

:root {
    --primary-text-color: #000000;
    --header-font-size: calc(10px + 2vmin);
    --name-head-color: #32c0f4;
    --sub-name-head-color: #e97124;
    --link-color: #280a00;
    --animation-duration: 20s;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    -webkit-animation: App-logo-spin infinite var(--animation-duration) linear;
            animation: App-logo-spin infinite var(--animation-duration) linear;
}

@media (prefers-reduced-motion: reduce) {
    .App-logo {
        -webkit-animation: none;
                animation: none;
    }
}

.header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-size: var(--header-font-size);
    color: #000000;
    color: var(--primary-text-color);
    margin-top: -8vh;
}

.name_head {
    color: #32c0f4;
    color: var(--name-head-color);
    font-size: calc(28px + 2vw);
}

.sub_name_head {
    color: #e97124;
    color: var(--sub-name-head-color);
    font-size: calc(4px + 2vw);
}

.link {
    color: #280a00;
    color: var(--link-color);
}

.head_desc {
    line-height: calc(2px + 3vh);
    word-wrap: break-word;
    font-size: calc(4px + 2vmin);
}

@media screen and (max-width: 1000px) {
    .social_media {
        display: none;
    }
}

.social_media {
    bottom: 0;
    left: 0;
    position: fixed;
}

.small_list {
    list-style: none;
}

.small_icons {
    margin-left: -2px;
    width: 18px;
    height: 18px;
    padding: 2px;
}

.rotate_icons {
    font-size: 22px;
}

.rotate_first_icons {
    margin-bottom: -6px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.rotate_second_icons {
    margin-top: -3px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

