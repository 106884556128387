@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

:root {
    --main-font: 'Source Code Pro', Menlo, Monaco, Consolas, "Courier New", monospace;
    --background-color: #fafafa;
    --link-color: black;
    --link-hover-font-weight: 600;
}

body {
    margin: 0;
    font-family: var(--main-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
}

a {
    color: var(--link-color);
    text-decoration: none;
}

a:hover {
    font-weight: var(--link-hover-font-weight);
}

.mePic {
    width: 450px;
}
